import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Next = ({ onClick, style, className }) => {
  return (
    <FaChevronRight
      className={className}
      onClick={onClick}
      style={{
        color: "gray",
        width: "2.428rem",
        height: "4rem",
        ...style,
      }}
    />
  )
}

const Prev = ({ onClick, style, className }) => {
  return (
    <FaChevronLeft
      onClick={onClick}
      className={className}
      style={{
        color: "gray",
        width: "2.428rem",
        height: "4rem",
        ...style,
      }}
    />
  )
}

const nl2br = text => {
  return text.replace(/(?:\r\n|\r|\n)/g, "<br />")
}

const SliderItem = ({
  slider: { name, position, education, experience, profile },
}) => {
  return (
    <div className="mx-auto text-center text-gray-700">
      <GatsbyImage
        image={profile}
        alt={name}
        className="z-10 mx-auto h-48 w-48 rounded-full"
      />
      <div className="mt-6">
        <span className="mr-3 text-xl font-bold">{name}</span>
        <span>{position}</span>
      </div>
      <div className="mx-auto mb-6 mt-2 w-32 border-2 border-orange-400" />
      <div className="text-sm">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {nl2br(education)}
        </ReactMarkdown>
      </div>
      <div className="mt-8 text-sm">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {nl2br(experience)}
        </ReactMarkdown>
      </div>
    </div>
  )
}
const MemberSlider = ({ sliders }) => {
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideToShow, setSlideToShow] = useState(0)
  useEffect(() => {
    if (slideToShow === 3) {
      const pointer = sliderRef.current
      pointer.slickGoTo(1)
    }
  }, [slideToShow])
  const SetInit = () => {
    const lengthOfSlideToShow = sliderRef.current.innerSlider.props.slidesToShow

    setSlideToShow(lengthOfSlideToShow)
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    lazyLoad: true,
    initialSlide: currentSlide,
    arrows: true,
    prevArrow: <Prev />,
    nextArrow: <Next />,
    centerMode: false,
    centerPadding: "32px",
    swipeToSlide: true,
    afterChange: current => {
      setCurrentSlide(current)
    },
    onReInit: SetInit,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  }
  const sliderList = sliders.map((slider, index) => (
    <SliderItem slider={slider} key={index} />
  ))

  return (
    <Slider {...settings} ref={sliderRef}>
      {sliderList}
    </Slider>
  )
}

SliderItem.propTypes = {
  slider: PropTypes.object,
}
Prev.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
}
Next.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
}
MemberSlider.propTypes = {
  sliders: PropTypes.array,
}

export default MemberSlider
